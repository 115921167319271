<template>
  <div class="zone">
    <TJDetailTitle :titleData="'基本信息'" />
    <TJDetailMenu :dataList="dataList" />

    <TJDetailTitle :titleData="'维修信息'" />
    <TJDetailMenu :dataList="dataListB" />

    <TJDetailTitle :titleData="'维修单'" />
    <!-- <el-button type="primary" v-print="'#printMe'">打印维修单</el-button>
    <el-button type="primary" @click="download">下载维修单</el-button> -->
    <span v-if="detailData.pdf_file">PDF加载可能较慢,请等待...</span>
    <span v-else>暂无维修单</span>
    <div class="printBox">
      <embed 
        id="printMe"
        :src="detailData.pdf_file"
        type="application/pdf"
        width="100%"
        height="800px" />
    </div>
  </div>
</template>

<script>
import jsPDF from 'jspdf'
import { getBase64 } from '@/utils/common'
import apiDetail from '@/api/searchDetail.js'
import TJDetailTitle from '@/components/TJDetailTitle'
import TJDetailMenu from '@/components/TJDetailMenu'
export default {
  name: 'RepairtaskDetail',
  components: { TJDetailTitle, TJDetailMenu },

  data() {
    return {
      detailId: '',
      dataList: [
        {
          name: '医院',
          value: '',
          width: '33%',
        },
        {
          name: '机号',
          value: '',
          width: '33%',
        },
        {
          name: '仪器型号',
          value: '',
          width: '33%',
          rightBorder: false,
        },
        {
          name: '经销商',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
        {
          name: '售后人员',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
      ],
      dataListB: [
        {
          name: '故障类型',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
        {
          name: '维修时间',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
      ],
      detailData: {},
      pdf_file:"",
    }
  },
  created() {
    this.detailId = this.$route.query.id
    this.gitrepairtaskDetail()
  },
  methods: {
    gitrepairtaskDetail() {
      apiDetail.repairtaskDetail({ task_id: this.detailId }).then((res) => {
        this.detailData = res.data
        this.pdf_file= res.data.pdf_file;
        this.dataList[0].value = this.detailData.hospital_name
        this.dataList[1].value = this.detailData.instrument_id
        this.dataList[2].value = this.detailData.platform_unique
        this.dataList[3].value = this.detailData.customer_name
        this.dataList[4].value = this.detailData.engineer_name

        this.dataListB[0].value = this.detailData.fault_type_name
        this.dataListB[1].value = this.detailData.implement_date
      })
    },
    download() {
      let _this = this
      if(_this.detailData.pdf_file == '' || _this.detailData.pdf_file == null){
        this.$message.warning('下载路径为空！')
        return
      }
      getBase64(_this.detailData.pdf_file, (url) => {
        var imageData = url
        var doc = new jsPDF('landscape', 'pt', [205, 115])
        doc.addImage(imageData, 'PNG', 0, 0, 205, 115)
        doc.save('a4.pdf')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.printBox{
  margin-top: px(20);
}
</style>
